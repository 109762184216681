import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import Expertise01 from '../../assets/Expertise/expertise01.png';
import Expertise02 from '../../assets/Expertise/expertise02.png';
import Expertise03 from '../../assets/Expertise/expertise03.png';
import Expertise04 from '../../assets/Expertise/expertise04.png';
import Expertise05 from '../../assets/Expertise/expertise05.png';
import Expertise01mobile from '../../assets/mobile/Expertise/EXPERTISE1.png';
import Expertise02mobile from '../../assets/mobile/Expertise/EXPERTISE2.png';
import Expertise03mobile from '../../assets/mobile/Expertise/EXPERTISE3.png';
import Expertise04mobile from '../../assets/mobile/Expertise/EXPERTISE4.png';
import Expertise05mobile from '../../assets/mobile/Expertise/EXPERTISE5.png';
import { getScreenWidth } from '../../utils/getScreenWidth';
import './epxertize.css'

function Epxertize() {

    const { t } = useTranslation();
    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    },  [window.innerWidth])

    return (
        <div className='expertize-container'>
            <h3>EXPERTISE</h3>

            <div className='expertize-container-row'>
                <img src={(screenWidth <= 480 ) ? Expertise01mobile : Expertise01} alt="expertize-img-01" />
                <h3 id='expertize-architecture-title'>
                    {t("expertize-container-row-card-title-architecture")}
                </h3>
                <p id='expertize-architecture-body'>
                    {t("expertize-container-row-card-body-architecture-01")}
                    <br />
                    {t("expertize-container-row-card-body-architecture-02")}
                    <br />
                    {t("expertize-container-row-card-body-architecture-03")}
                </p>
            </div>

            <div className='expertize-container-row'>
                <img src={(screenWidth <= 480 ) ? Expertise02mobile : Expertise02} alt="expertize-img-02" />
                <h3 id='expertize-architecture-title'>
                    {t("expertize-container-row-card-title-interior")}
                </h3>
                <p id='expertize-architecture-body'>
                    {t("expertize-container-row-card-body-interior-01")}
                    <br />
                    {t("expertize-container-row-card-body-interior-02")}
                    <br />
                    {t("expertize-container-row-card-body-interior-03")}
                </p>
            </div>

            <div className='expertize-container-row'>
                <img src={(screenWidth <= 480 ) ? Expertise03mobile : Expertise03} alt="expertize-img-03" />
                <h3 id='expertize-architecture-title'>
                    {t("expertize-container-row-card-title-engineering")}
                </h3>
                <p id='expertize-architecture-body'>
                    {t("expertize-container-row-card-body-engineering-01")}
                    <br />
                    {t("expertize-container-row-card-body-engineering-02")}
                    <br />
                    {t("expertize-container-row-card-body-engineering-03")}
                    <br />
                    {t("expertize-container-row-card-body-engineering-04")}
                </p>
            </div>

            <div className='expertize-container-row'>
                <img src={(screenWidth <= 480 ) ? Expertise04mobile : Expertise04} alt="expertize-img-04" />
                <h3 id='expertize-architecture-title'>
                    {t("expertize-container-row-card-title-project-management")}
                </h3>
                <p id='expertize-architecture-body'>
                    {t("expertize-container-row-card-body-project-management-01")}
                    <br />
                    {t("expertize-container-row-card-body-project-management-02")}
                    <br />
                    {t("expertize-container-row-card-body-project-management-03")}
                </p>
            </div>

            <div className='expertize-container-row'>
                <img src={(screenWidth <= 480 ) ? Expertise05mobile : Expertise05} alt="expertize-img-05" />
                <h3 id='expertize-architecture-title'>
                    {t("expertize-container-row-card-title-construction")}
                </h3>
                <p id='expertize-architecture-body'>
                    {t("expertize-container-row-card-body-construction-01")}
                    <br />
                    {t("expertize-container-row-card-body-construction-02")}
                    <br />
                    {t("expertize-container-row-card-body-construction-03")}
                </p>
            </div>

        </div>
    )
}

export default Epxertize
