import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import Homepage01 from '../../../assets/homepage01.png';
import Homepage02 from '../../../assets/homepage02.png';
import Homepage03 from '../../../assets/homepage03.png';
import HomepageMobile01 from '../../../assets/mobile/homepage01.png';
import HomepageMobile02 from '../../../assets/mobile/homepage02.png';
import HomepageMobile03 from '../../../assets/mobile/homepage03.png';
import { getScreenWidth } from '../../../utils/getScreenWidth';
import './hero.css'

const fadeProperties = {
    duration: 3000,
    pauseOnHover: false,
    arrows: false,
    indicators: true
};

function Hero() {

    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    }, [window.innerWidth])

    return (
        <div className='hero-container'>

            <div className="slide-container">
                <Fade {...fadeProperties}>
                    <div className="each-fade">
                        <div className="image-container">
                            <img src={(screenWidth <= 480) ? HomepageMobile01 : Homepage01} alt="Homepage01" />
                        </div>
                        <h2>MAK ARCHITECTS &amp; ENGINEERS</h2>
                        <h4>We bring aesthetics.</h4>
                    </div>

                    <div className="each-fade">
                        <div className="image-container">
                            <img src={(screenWidth <= 480) ? HomepageMobile02 : Homepage02} alt="Homepage02" />
                        </div>
                        <h2>MAK ARCHITECTS &amp; ENGINEERS</h2>
                        <h4>We shape your needs.</h4>
                    </div>

                    <div className="each-fade">
                        <div className="image-container">
                            <img src={(screenWidth <= 480) ? HomepageMobile03 : Homepage03} alt="Homepage03" />
                        </div>
                        <h2>MAK ARCHITECTS &amp; ENGINEERS</h2>
                        <h4>We build your vision.</h4>
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default Hero
