import React from 'react';
import CustomNavbar from '../../../components/Navbar/Navbar';
import Prefooter from '../../prefooter/prefooter';
import Footer from '../../footer/footer';

import KeranisHeader from '../../../assets/Keranis/KeranisHeader.png';
import Keranis01 from '../../../assets/Keranis/Keranis01.png';
import Keranis02 from '../../../assets/Keranis/Keranis02.png';
import Keranis03 from '../../../assets/Keranis/Keranis03.png';
import Keranis04 from '../../../assets/Keranis/Keranis04.png';
import Keranis05 from '../../../assets/Keranis/Keranis05.png';
import Keranis06 from '../../../assets/Keranis/Keranis06.png';
import Keranis07 from '../../../assets/Keranis/Keranis07.png';
import Keranis08 from '../../../assets/Keranis/Keranis08.png';
import Keranis09 from '../../../assets/Keranis/Keranis09.png';
import Keranis10 from '../../../assets/Keranis/Keranis10.png';
import Keranis11 from '../../../assets/Keranis/Keranis11.png';
import Keranis12 from '../../../assets/Keranis/Keranis12.png';
import Keranis13 from '../../../assets/Keranis/Keranis13.png';
import KeranisMobileHeader from '../../../assets/mobile/Keranis/KeranisHeader.png';
import KeranisMobile01 from '../../../assets/mobile/Keranis/Keranis01.png';
import KeranisMobile02 from '../../../assets/mobile/Keranis/Keranis02.png';
import KeranisMobile03 from '../../../assets/mobile/Keranis/Keranis03.png';
import KeranisMobile04 from '../../../assets/mobile/Keranis/Keranis04.png';
import KeranisMobile05 from '../../../assets/mobile/Keranis/Keranis05.png';
import KeranisMobile06 from '../../../assets/mobile/Keranis/Keranis06.png';
import KeranisMobile07 from '../../../assets/mobile/Keranis/Keranis07.png';
import KeranisMobile08 from '../../../assets/mobile/Keranis/Keranis08.png';
import KeranisMobile09 from '../../../assets/mobile/Keranis/Keranis09.png';
import KeranisMobile10 from '../../../assets/mobile/Keranis/Keranis10.png';
import KeranisMobile11 from '../../../assets/mobile/Keranis/Keranis11.png';
import KeranisMobile12 from '../../../assets/mobile/Keranis/Keranis12.png';
import KeranisMobile13 from '../../../assets/mobile/Keranis/Keranis13.png';

import '../ProjectStructure.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { getScreenWidth } from '../../../utils/getScreenWidth';

function Keranis() {

    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    },  [window.innerWidth])

    return (
        <div className='keranis-container'>
            <CustomNavbar />
            <div className='project-content'>
                <div className='project-content-header'>
                    <img src={(screenWidth <= 480 ) ? KeranisMobileHeader : KeranisHeader} alt="keranis" />
                    <p>Inhabiting Keranis</p>
                </div>
                <div className='project-content-paragraph'>
                    <p>
                        PROJECT: Inhabiting Keranis (Proposal for the reconstruction of the Keranis tobacco factory)
                        <br />
                        TYPE: Residential, Public
                        <br />
                        LOCATION: Piraeus, Greece
                    </p>
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? KeranisMobile01 : Keranis01} alt="Keranis01" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? KeranisMobile02 : Keranis02} alt="Keranis02" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? KeranisMobile03 : Keranis03} alt="Keranis03" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? KeranisMobile04 : Keranis04} alt="Keranis04" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? KeranisMobile05 : Keranis05} alt="Keranis05" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? KeranisMobile06 : Keranis06} alt="Keranis06" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? KeranisMobile07 : Keranis07} alt="Keranis07" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? KeranisMobile08 : Keranis08} alt="Keranis08" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? KeranisMobile09 : Keranis09} alt="Keranis09" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? KeranisMobile10 : Keranis10} alt="Keranis10" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? KeranisMobile11 : Keranis11} alt="Keranis11" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? KeranisMobile12 : Keranis12} alt="Keranis12" />
                    <img src={(screenWidth <= 480 ) ? KeranisMobile13 : Keranis13} alt="Keranis13" />
                </div>
            </div>
            <Prefooter />
            <Footer />
        </div>
    )
}

export default Keranis