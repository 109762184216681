import Routing from './navigation/routing';

import './App.css';


function App() {
  return (
    <Routing/>
  );
}

export default App;
