import React from 'react';
import CustomNavbar from '../../../components/Navbar/Navbar';
import Prefooter from '../../prefooter/prefooter';
import Footer from '../../footer/footer';

import BuboHeader from '../../../assets/Bubo/BuboHeader.png';
import Bubo01 from '../../../assets/Bubo/Bubo01.png';
import Bubo02 from '../../../assets/Bubo/Bubo02.png';
import Bubo03 from '../../../assets/Bubo/Bubo03.png';
import Bubo04 from '../../../assets/Bubo/Bubo04.png';
import Bubo05 from '../../../assets/Bubo/Bubo05.png';
import Bubo06 from '../../../assets/Bubo/Bubo06.png';
import Bubo07 from '../../../assets/Bubo/Bubo07.png';
import Bubo08 from '../../../assets/Bubo/Bubo08.png';
import Bubo09 from '../../../assets/Bubo/Bubo09.png';
import Bubo10 from '../../../assets/Bubo/Bubo10.png';
import Bubo11 from '../../../assets/Bubo/Bubo11.png';
import Bubo12 from '../../../assets/Bubo/Bubo12.png';
import Bubo13 from '../../../assets/Bubo/Bubo13.png';
import Bubo14 from '../../../assets/Bubo/Bubo14.png';
import Bubo15 from '../../../assets/Bubo/Bubo15.png';
import Bubo16 from '../../../assets/Bubo/Bubo16.png';
import Bubo17 from '../../../assets/Bubo/Bubo17.png';
import Bubo01Header from '../../../assets/mobile/Bubo/BuboHeader.png';
import Bubo02mobile from '../../../assets/mobile/Bubo/BuboMobile02.png';
import Bubo03mobile from '../../../assets/mobile/Bubo/BuboMobile03.png';
import Bubo04mobile from '../../../assets/mobile/Bubo/BuboMobile04.png';
import Bubo05mobile from '../../../assets/mobile/Bubo/BuboMobile05.png';
import Bubo06mobile from '../../../assets/mobile/Bubo/BuboMobile06.png';
import Bubo07mobile from '../../../assets/mobile/Bubo/BuboMobile07.png';
import Bubo08mobile from '../../../assets/mobile/Bubo/BuboMobile08.png';
import Bubo09mobile from '../../../assets/mobile/Bubo/BuboMobile09.png';
import Bubo10mobile from '../../../assets/mobile/Bubo/BuboMobile10.png';
import Bubo11mobile from '../../../assets/mobile/Bubo/BuboMobile11.png';
import Bubo12mobile from '../../../assets/mobile/Bubo/BuboMobile12.png';
import Bubo13mobile from '../../../assets/mobile/Bubo/BuboMobile13.png';
import Bubo14mobile from '../../../assets/mobile/Bubo/BuboMobile14.png';
import Bubo15mobile from '../../../assets/mobile/Bubo/BuboMobile15.png';
import Bubo16mobile from '../../../assets/mobile/Bubo/BuboMobile16.png';
import Bubo17mobile from '../../../assets/mobile/Bubo/BuboMobile17.png';


import '../ProjectStructure.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { getScreenWidth } from '../../../utils/getScreenWidth';

function Bubo() {
    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    }, [window.innerWidth])


    return (
        <div className='bubo-container'>
            <CustomNavbar />
            <div className='project-content'>
                <div className='project-content-header'>
                    <img src={(screenWidth <= 480) ? Bubo01Header : BuboHeader} alt="bubo" />
                    <p>Restaurant & Bar in Athens</p>
                </div>
                <div className='project-content-paragraph'>
                    <p>
                        PROJECT: Restaurant & Bar in Athens
                        <br />
                        TYPE: Restaurant Design
                        <br />
                        LOCATION: Pagkrati, Greece
                        <br />
                        YEAR: 2020
                    </p>
                </div>
                {
                    (screenWidth > 480) && <div className='project-content-one-img'>
                        <img src={Bubo01} alt="Bubo01" />
                    </div>
                }
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480) ? Bubo02mobile : Bubo02} alt="Bubo02" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480) ? Bubo03mobile : Bubo03} alt="Bubo03" />
                </div>

                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480) ? Bubo04mobile : Bubo04} alt="Bubo03" />
                </div>

                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480) ? Bubo05mobile : Bubo05} alt="Bubo03" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480) ? Bubo06mobile : Bubo06} alt="Bubo04" />
                    <img src={(screenWidth <= 480) ? Bubo07mobile : Bubo07} alt="Bubo05" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480) ? Bubo08mobile : Bubo08} alt="Bubo08" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480) ? Bubo09mobile : Bubo09} alt="Bubo09" />
                    <img src={(screenWidth <= 480) ? Bubo10mobile : Bubo10} alt="Bubo10" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480) ? Bubo11mobile : Bubo11} alt="Bubo09" />
                    <img src={(screenWidth <= 480) ? Bubo12mobile : Bubo12} alt="Bubo10" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480) ? Bubo13mobile : Bubo13} alt="Bubo12" />
                    <img src={(screenWidth <= 480) ? Bubo14mobile : Bubo14} alt="Bubo13" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480) ? Bubo15mobile : Bubo15} alt="Bubo12" />
                    <img src={(screenWidth <= 480) ? Bubo16mobile : Bubo16} alt="Bubo13" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480) ? Bubo17mobile : Bubo17} alt="Bubo14" />
                </div>
            </div>
            <Prefooter />
            <Footer />
        </div>
    )
}

export default Bubo