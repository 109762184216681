import React from 'react';
import CustomNavbar from '../../../components/Navbar/Navbar';
import Prefooter from '../../prefooter/prefooter';
import Footer from '../../footer/footer';

import ParosHeader from '../../../assets/Paros/ParosHeader.png';
import Paros01 from '../../../assets/Paros/Paros01.png';
import Paros02 from '../../../assets/Paros/Paros02.png';
import Paros03 from '../../../assets/Paros/Paros03.png';
import Paros04 from '../../../assets/Paros/Paros04.png';
import Paros05 from '../../../assets/Paros/Paros05.png';
import Paros06 from '../../../assets/Paros/Paros06.png';
import Paros07 from '../../../assets/Paros/Paros07.png';
import Paros08 from '../../../assets/Paros/Paros08.png';
import Paros09 from '../../../assets/Paros/Paros09.png';
import Paros10 from '../../../assets/Paros/Paros10.png';
import Paros11 from '../../../assets/Paros/Paros11.png';
import Paros12 from '../../../assets/Paros/Paros12.png';
import Paros13 from '../../../assets/Paros/Paros13.png';
import ParosMobileHeader from '../../../assets/mobile/Paros/ParosHeader.png';
import ParosMobile01 from '../../../assets/mobile/Paros/Paros01.png';
import ParosMobile02 from '../../../assets/mobile/Paros/Paros02.png';
import ParosMobile03 from '../../../assets/mobile/Paros/Paros03.png';
import ParosMobile04 from '../../../assets/mobile/Paros/Paros04.png';
import ParosMobile05 from '../../../assets/mobile/Paros/Paros05.png';
import ParosMobile06 from '../../../assets/mobile/Paros/Paros06.png';
import ParosMobile07 from '../../../assets/mobile/Paros/Paros07.png';
import ParosMobile08 from '../../../assets/mobile/Paros/Paros08.png';
import ParosMobile09 from '../../../assets/mobile/Paros/Paros09.png';
import ParosMobile10 from '../../../assets/mobile/Paros/Paros10.png';
import ParosMobile11 from '../../../assets/mobile/Paros/Paros11.png';
import ParosMobile12 from '../../../assets/mobile/Paros/Paros12.png';
import ParosMobile13 from '../../../assets/mobile/Paros/Paros13.png';

import '../ProjectStructure.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { getScreenWidth } from '../../../utils/getScreenWidth';

function Paros() {

    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    },  [window.innerWidth])


    return (
        <div className='paros-container'>
            <CustomNavbar />
            <div className='project-content'>
                <div className='project-content-header'>
                    <img src={(screenWidth <= 480 ) ? ParosMobileHeader : ParosHeader} alt="paros" />
                    <p>VILLA GREY IN PAROS</p>
                </div>
                <div className='project-content-paragraph'>
                    <p>
                        PROJECTS: Villa Grey in Paros
                        <br />
                        TYPE: Residential
                        <br />
                        LOCATION: Paros, Greece
                    </p>
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? ParosMobile01 : Paros01} alt="paros01"/>
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? ParosMobile02 : Paros02} alt="paros02"/>
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? ParosMobile03 : Paros03} alt="paros03"/>
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? ParosMobile04 : Paros04} alt="Paros04"/>
                    <img src={(screenWidth <= 480 ) ? ParosMobile05 : Paros05} alt="Paros05"/>
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? ParosMobile07 : Paros07} alt="Paros07"/>
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? ParosMobile06 : Paros06} alt="Paros06"/>
                    <img src={(screenWidth <= 480 ) ? ParosMobile08 : Paros08} alt="Paros08"/>
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? ParosMobile09 : Paros09} alt="paros03"/>
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? ParosMobile10 : Paros10} alt="Paros10"/>
                    <img src={(screenWidth <= 480 ) ? ParosMobile11 : Paros11} alt="Paros11"/>
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? ParosMobile12 : Paros12} alt="paros05"/>
                    <img src={(screenWidth <= 480 ) ? ParosMobile13 : Paros13} alt="paros06"/>
                </div>
            </div>
            <Prefooter />
            <Footer />
        </div>
    )
}

export default Paros