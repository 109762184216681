import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './fonts/CenturyGothic/GOTHIC.TTF';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';

import App from './App';
const loading = (
  <div className='py-4 text-center'> Loading...</div>
);

ReactDOM.render(
  <Suspense fallback={loading}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);