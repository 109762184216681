import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Contact from '../../../assets/contact.png'
import ContactMobile from '../../../assets/mobile/contact.png'
import { getScreenWidth } from '../../../utils/getScreenWidth';
import './header.css'

function ContactHeader() {
    const { t } = useTranslation();
    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    }, [window.innerWidth])
    return (
        <div className='contact-header'>
            <img src={(screenWidth <= 480) ? ContactMobile : Contact} alt='concrete-wall'/>
            <p>{t('navbar-contact')}</p>
        </div>
    )
}

export default ContactHeader
