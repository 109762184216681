import React from 'react';
import CustomNavbar from '../../../components/Navbar/Navbar';
import Prefooter from '../../prefooter/prefooter';
import Footer from '../../footer/footer';

import PanourgiaHeader from '../../../assets/Panourgia/PanourgiaHeader.png';
import Panourgia01 from '../../../assets/Panourgia/Panourgia01.png';
import Panourgia02 from '../../../assets/Panourgia/Panourgia02.png';
import Panourgia03 from '../../../assets/Panourgia/Panourgia03.png';
import Panourgia04 from '../../../assets/Panourgia/Panourgia04.png';
import Panourgia05 from '../../../assets/Panourgia/Panourgia05.png';
import Panourgia06 from '../../../assets/Panourgia/Panourgia06.png';
import PanourgiaMobileHeader from '../../../assets/mobile/Panourgia/PanourgiaHeader.png';
import PanourgiaMobile01 from '../../../assets/mobile/Panourgia/Panourgia01.png';
import PanourgiaMobile02 from '../../../assets/mobile/Panourgia/Panourgia02.png';
import PanourgiaMobile03 from '../../../assets/mobile/Panourgia/Panourgia03.png';
import PanourgiaMobile04 from '../../../assets/mobile/Panourgia/Panourgia04.png';
import PanourgiaMobile05 from '../../../assets/mobile/Panourgia/Panourgia05.png';
import PanourgiaMobile06 from '../../../assets/mobile/Panourgia/Panourgia06.png';
import '../ProjectStructure.css';
import './Panourgia.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { getScreenWidth } from '../../../utils/getScreenWidth';

function Panourgia() {

    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    }, [window.innerWidth])

    return (
        <div className='panourgia-container'>
            <CustomNavbar />
            <div className='project-content'><div className='project-content-header'>
            <img src={(screenWidth <= 480) ? PanourgiaHeader : PanourgiaMobileHeader} alt="Panourgia" />
                    <p>Block of flats</p>
                </div>
                <div className='project-content-paragraph'>
                <p>
                        PROJECT: Block of flats
                        <br />
                        TYPE: Residential
                        <br />
                        LOCATION: Nikaia, Greece
                    </p>
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? PanourgiaMobile01 : Panourgia01} alt="Panourgia01" />
                    <img src={(screenWidth <= 480 ) ? PanourgiaMobile02 : Panourgia02} alt="Panourgia02" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? PanourgiaMobile03 : Panourgia03} alt="Panourgia03" />
                    <img src={(screenWidth <= 480 ) ? PanourgiaMobile04 : Panourgia04} alt="Panourgia04" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? PanourgiaMobile05 : Panourgia05} alt="Panourgia05" />
                    <img src={(screenWidth <= 480 ) ? PanourgiaMobile06 : Panourgia06} alt="Panourgia06" />
                </div>
            </div>
            <Prefooter />
            <Footer />
        </div>
    )
}

export default Panourgia