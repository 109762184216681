import React from 'react'
import { useTranslation } from "react-i18next";

import './prefooter.css'

function Prefooter() {

    const { t } = useTranslation();

    return (
        <div className='prefooter-container'>
            <h5>LET'S TALK ABOUT YOUR PROJECT</h5>
        </div>
    )
}

export default Prefooter
