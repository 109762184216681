import React from 'react'
import { Prefooter, ProjectsCarousel, ProjectsGallery, Footer } from '..'
import Navbar from '../../components/Navbar/Navbar'
import ProjectsHeader from './header/header'
import './projects.css'

function projects() {
    return (
        <div className='projects-container'>
            <Navbar/>
            <ProjectsHeader/>
            <ProjectsGallery/>
            <Prefooter/>
            <Footer/>
        </div>
    )
}

export default projects
