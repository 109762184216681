import React, { useState } from 'react';
import CustomNavbar from '../../../components/Navbar/Navbar';
import Prefooter from '../../prefooter/prefooter';
import Footer from '../../footer/footer';

import DrosiaHeader from '../../../assets/Drosia/DrosiaHeader.png';
import Drosia01 from '../../../assets/Drosia/Drosia01.png';
import Drosia02 from '../../../assets/Drosia/Drosia02.png';
import Drosia03 from '../../../assets/Drosia/Drosia03.png';
import Drosia04 from '../../../assets/Drosia/Drosia04.png';
import Drosia05 from '../../../assets/Drosia/Drosia05.png';
import Drosia06 from '../../../assets/Drosia/Drosia06.png';
import Drosia07 from '../../../assets/Drosia/Drosia07.png';
import Drosia08 from '../../../assets/Drosia/Drosia08.png';
import DrosiaMobileHeader from '../../../assets/Drosia/DrosiaHeader.png';
import DrosiaMobile01 from '../../../assets/Drosia/Drosia01.png';
import DrosiaMobile02 from '../../../assets/Drosia/Drosia02.png';
import DrosiaMobile03 from '../../../assets/Drosia/Drosia03.png';
import DrosiaMobile04 from '../../../assets/Drosia/Drosia04.png';
import DrosiaMobile05 from '../../../assets/Drosia/Drosia05.png';
import DrosiaMobile06 from '../../../assets/Drosia/Drosia06.png';
import DrosiaMobile07 from '../../../assets/Drosia/Drosia07.png';
import DrosiaMobile08 from '../../../assets/Drosia/Drosia08.png';

import '../ProjectStructure.css';
import { useEffect } from 'react';
import { getScreenWidth } from '../../../utils/getScreenWidth';

function Drosia() {

    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    },  [window.innerWidth])

    return (
        <div className='drosia-container'>
            <CustomNavbar />
            <div className='project-content'>
                <div className='project-content-header'>
                    <img src={(screenWidth <= 480 ) ? DrosiaMobileHeader : DrosiaHeader} alt="drosia" />
                    <p>Complex of Residences in Drosia</p>
                </div>
                <div className='project-content-paragraph'>
                    <p>
                        PROJECT: Complex of Residences in Drosia
                        <br />
                        TYPE: Residential
                        <br />
                        LOCATION: Drosia, Greece
                    </p>
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? DrosiaMobile01 : Drosia01} alt="Drosia01" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? DrosiaMobile02 : Drosia02} alt="Drosia02" />
                    <img src={(screenWidth <= 480 ) ? DrosiaMobile03 : Drosia03} alt="Drosia03" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? DrosiaMobile04 : Drosia04} alt="Drosia04" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? DrosiaMobile05 : Drosia05} alt="Drosia06" />
                    <img src={(screenWidth <= 480 ) ? DrosiaMobile06 : Drosia06} alt="Drosia07" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? DrosiaMobile07 : Drosia07} alt="Drosia05" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? DrosiaMobile08 : Drosia08} alt="Drosia08" />
                </div>
            </div>
            <Prefooter />
            <Footer />
        </div>
    )
}

export default Drosia