import React from 'react'

import './footer.css'
import Facebook from '../../assets/facebook.png';
import Instagram from '../../assets/instagram.png';
import Logo from '../../assets/mak-architects-logo.png';
import { Link } from 'react-router-dom';


const Footer = () => {


    return (
        <div className="footer-container">

            <div className='footer-container-row-img'>
                <img src={Logo} alt="mak-architects-logo" />
            </div>

            <div className="footer-container-row">
                <div className='footer-container-row-col' id='footer-container-row-col-1'>
                    <div className='footer-container-row-company-name'>
                        <p className='footer-container-row-col-title'>CONTACT</p>
                        <p>+30 2169009989</p>
                        <p>+30 6981127122</p>
                        <p>mak.architects.engineers@gmail.com</p>
                        <p>info@makarchitectsengineers.com</p>
                    </div>
                </div>
                <div className='footer-container-row-col' id='footer-container-row-col-2'>
                    <div className='footer-container-row-menu'>
                        <Link to="/studio">
                            STUDIO
                        </Link>
                        <Link to="/services">
                            SERVICES
                        </Link>
                        <Link to="/projects">
                            PROJECTS
                        </Link>
                        <Link to="/contact">
                            CONTACT
                        </Link>
                    </div>
                </div>
                <div className='footer-container-row-col' id='footer-container-row-col-3'>
                    <div className='footer-container-row-contact-details'>
                        <p className='footer-container-row-col-title'>OFFICES</p>
                        <p>Ekali | Persefonis 3, 14578, Athens</p>
                        <p>Nikaia | Olimpou 21, 18454, Piraeus</p>
                    </div>
                </div>
                <div className='footer-container-row-col' id='footer-container-row-col-4'>
                    <div className='footer-container-row-menu'>
                        <Link to="/studio">
                            STUDIO
                        </Link>
                        <Link to="/services">
                            SERVICES
                        </Link>
                        <Link to="/projects">
                            PROJECTS
                        </Link>
                        <Link to="/contact">
                            CONTACT
                        </Link>
                    </div>
                </div>
            </div>

            <div className='footer-container-row-social'>
                <a href="https://www.facebook.com/makarchitectsengineers" target="_blank" rel="noreferrer">
                    <img src={Facebook} alt="facebook-logo" />
                </a>
                <a href="https://www.instagram.com/mak.architects.engineers/" target="_blank" rel="noreferrer">
                    <img src={Instagram} alt="instagram-logo" />
                </a>
            </div>

        </div>
    )
}

export default Footer
