import React from 'react'
import { useTranslation } from 'react-i18next';
import { useInView } from "react-intersection-observer"
import './section.css'

function Section() {
    const { t } = useTranslation();
    const [ref, inView] = useInView({
        threshold: 0,
      })
    
    return (
        <div className='services-section'>
            <p className={!inView ? "services-animation" : null }>
                {t("services-section-part-01")}
                <br /><br />{t("services-section-part-02")}
                <br /><br />{t("services-section-part-03")}
            </p>

        </div>
    )
}

export default Section
