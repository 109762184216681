import React from 'react';
import CustomNavbar from '../../../components/Navbar/Navbar';
import Prefooter from '../../prefooter/prefooter';
import Footer from '../../footer/footer';

import EKOHeader from '../../../assets/EKO/EKOHeader.png';
import EKO01 from '../../../assets/EKO/EKO01.png';
import EKO02 from '../../../assets/EKO/EKO02.png';
import EKO03 from '../../../assets/EKO/EKO03.png';
import EKO04 from '../../../assets/EKO/EKO04.png';
import EKO05 from '../../../assets/EKO/EKO05.png';
import EKO06 from '../../../assets/EKO/EKO06.png';
import EKO07 from '../../../assets/EKO/EKO07.png';
import EKO08 from '../../../assets/EKO/EKO08.png';
import EKO09 from '../../../assets/EKO/EKO09.png';
import EKOMobileHeader from '../../../assets/mobile/EKO/EKOHeader.png';
import EKOMobile01 from '../../../assets/mobile/EKO/EKO01.png';
import EKOMobile02 from '../../../assets/mobile/EKO/EKO02.png';
import EKOMobile03 from '../../../assets/mobile/EKO/EKO03.png';
import EKOMobile04 from '../../../assets/mobile/EKO/EKO04.png';
import EKOMobile05 from '../../../assets/mobile/EKO/EKO05.png';
import EKOMobile06 from '../../../assets/mobile/EKO/EKO06.png';
import EKOMobile07 from '../../../assets/mobile/EKO/EKO07.png';
import EKOMobile08 from '../../../assets/mobile/EKO/EKO08.png';
import EKOMobile09 from '../../../assets/mobile/EKO/EKO09.png';
import '../ProjectStructure.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { getScreenWidth } from '../../../utils/getScreenWidth';

function EKO() {

    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    },  [window.innerWidth])

    return (
        <div className='drosia-container'>
            <CustomNavbar />
            <div className='project-content'>
                <div className='project-content-header'>
                    <img src={(screenWidth <= 480 ) ? EKOMobileHeader : EKOHeader} alt="EKO" />
                    <p>Gas Station in Kifissos</p>
                </div>
                <div className='project-content-paragraph'>
                    <p>
                        PROJECT: Gas Station in Kifissos
                        <br />
                        TYPE: Commercial
                        <br />
                        LOCATION: Agios Ioannis Rentis, Greece
                        <br />
                        YEAR: 2016
                    </p>
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? EKOMobile01 : EKO01} alt="EKO01" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? EKOMobile02 : EKO02} alt="EKO01" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? EKOMobile03 : EKO03} alt="EKO02" />
                    <img src={(screenWidth <= 480 ) ? EKOMobile04 : EKO04} alt="EKO03" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? EKOMobile05 : EKO05} alt="EKO05" />
                    <img src={(screenWidth <= 480 ) ? EKOMobile06 : EKO06} alt="EKO06" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? EKOMobile07 : EKO07} alt="EKO05" />
                    <img src={(screenWidth <= 480 ) ? EKOMobile08 : EKO08} alt="EKO06" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? EKOMobile09 : EKO09} alt="EKO09" />
                </div>
            </div>
            <Prefooter />
            <Footer />
        </div>
    )
}

export default EKO