import React from 'react';
import CustomNavbar from '../../../components/Navbar/Navbar';
import Prefooter from '../../prefooter/prefooter';
import Footer from '../../footer/footer';

import VoulaImg from '../../../assets/Voula/VoulaHeader.png';
import Voula01 from '../../../assets/Voula/Voula01.png';
import Voula02 from '../../../assets/Voula/Voula02.png';
import Voula03 from '../../../assets/Voula/Voula03.png';
import Voula04 from '../../../assets/Voula/Voula04.png';
import Voula05 from '../../../assets/Voula/Voula05.png';
import Voula06 from '../../../assets/Voula/Voula06.png';
import Voula07 from '../../../assets/Voula/Voula07.png';
import VoulaMobileHeader from '../../../assets/mobile/Voula/VoulaHeader.png';
import VoulaMobile01 from '../../../assets/mobile/Voula/Voula01.png';
import VoulaMobile02 from '../../../assets/mobile/Voula/Voula02.png';
import VoulaMobile03 from '../../../assets/mobile/Voula/Voula03.png';
import VoulaMobile04 from '../../../assets/mobile/Voula/Voula04.png';
import VoulaMobile05 from '../../../assets/mobile/Voula/Voula05.png';
import VoulaMobile06 from '../../../assets/mobile/Voula/Voula06.png';
import VoulaMobile07 from '../../../assets/mobile/Voula/Voula07.png';
import '../ProjectStructure.css';
import './Voula.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { getScreenWidth } from '../../../utils/getScreenWidth';

function Voula() {

    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    }, [window.innerWidth])

    return (
        <div className='voula-project-container'>
            <CustomNavbar />
            <div className='project-content-header'>
                <img src={(screenWidth <= 480) ? VoulaMobileHeader : VoulaImg} alt="voula" />
                <p>Apartment Building Extension</p>
            </div>
            <div className='project-content-paragraph'>
                <p>
                    PROJECTS: Apartment Building Extension
                    <br />
                    TYPE: Residential
                    <br />
                    LOCATION: Voula, Greece
                </p>
            </div>

            <div className='project-content-one-img'>
                <img src={(screenWidth <= 480) ? VoulaMobile01 : Voula01} alt="Voula01" />
            </div>
            <div className='project-content-one-img'>
                <img src={(screenWidth <= 480) ? VoulaMobile02 : Voula02} alt="Voula02" />
            </div>
            <div className='project-content-two-imgs'>
                <img src={(screenWidth <= 480) ? VoulaMobile03 : Voula03} alt="Voula03" />
                <img src={(screenWidth <= 480) ? VoulaMobile04 : Voula04} alt="Voula04" />
            </div>
            <div className='project-content-two-imgs'>
                <img src={(screenWidth <= 480) ? VoulaMobile05 : Voula05} alt="Voula05" />
                <img src={(screenWidth <= 480) ? VoulaMobile06 : Voula06} alt="Voula06" />
            </div>
            <div className='project-content-one-img'>
                <img src={(screenWidth <= 480) ? VoulaMobile07 : Voula07} alt="Voula07" />
            </div>

            <Prefooter />
            <Footer />
        </div>
    )
}

export default Voula