import React from 'react'
import { ContactHeader, ContactOnSite, ContactSection, Prefooter, Footer } from '..'
import Navbar from '../../components/Navbar/Navbar'
import './contact.css'
import ContactUsForm from './ContactUsForm/ContactUsForm'

function contact() {
    return (
        <div>
            <Navbar />
            <ContactHeader />
            <ContactSection />
            <ContactOnSite />
            <ContactUsForm />
            <Prefooter />
            <Footer />
        </div>
    )
}

export default contact
