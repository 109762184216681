import React from 'react'
import { useTranslation } from 'react-i18next';

import ArchitectureDesign from '../../../assets/services.png'
import ArchitectureDesignMobile from '../../../assets/mobile/services.png'
import './header.css';
import { useState } from 'react';
import { getScreenWidth } from '../../../utils/getScreenWidth';
import { useEffect } from 'react';

function ServicesHeader() {
    const { t } = useTranslation();
    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    }, [window.innerWidth])
    return (
        <div className='services-header'>
            <div className='services-header-img'>
                <img src={(screenWidth <= 480) ? ArchitectureDesignMobile : ArchitectureDesign} alt='architecture-design'/>
                <p>{t('navbar-services')}</p>
            </div>
            
        </div>
    )
}

export default ServicesHeader
