import React from 'react';
import CustomNavbar from '../../../components/Navbar/Navbar';
import Prefooter from '../../prefooter/prefooter';
import Footer from '../../footer/footer';

import AlimosHeader from '../../../assets/Alimos/AlimosHeader.png';
import Alimos01 from '../../../assets/Alimos/Alimos01.png';
import Alimos02 from '../../../assets/Alimos/Alimos02.png';
import Alimos03 from '../../../assets/Alimos/Alimos03.png';
import Alimos04 from '../../../assets/Alimos/Alimos04.png';
import Alimos05 from '../../../assets/Alimos/Alimos05.png';
import Alimos06 from '../../../assets/Alimos/Alimos06.png';
import Alimos07 from '../../../assets/Alimos/Alimos07.png';
import Alimos08 from '../../../assets/Alimos/Alimos08.png';
import Alimos09 from '../../../assets/Alimos/Alimos09.png';
import Alimos10 from '../../../assets/Alimos/Alimos10.png';
import Alimos11 from '../../../assets/Alimos/Alimos11.png';
import Alimos12 from '../../../assets/Alimos/Alimos12.png';
import AlimosMobileHeader from '../../../assets/mobile/Alimos/AlimosHeader.png';
import AlimosMobile01 from '../../../assets/mobile/Alimos/Alimos01.png';
import AlimosMobile02 from '../../../assets/mobile/Alimos/Alimos02.png';
import AlimosMobile03 from '../../../assets/mobile/Alimos/Alimos03.png';
import AlimosMobile04 from '../../../assets/mobile/Alimos/Alimos04.png';
import AlimosMobile05 from '../../../assets/mobile/Alimos/Alimos05.png';
import AlimosMobile06 from '../../../assets/mobile/Alimos/Alimos06.png';
import AlimosMobile07 from '../../../assets/mobile/Alimos/Alimos07.png';
import AlimosMobile08 from '../../../assets/mobile/Alimos/Alimos08.png';
import AlimosMobile09 from '../../../assets/mobile/Alimos/Alimos09.png';
import AlimosMobile10 from '../../../assets/mobile/Alimos/Alimos10.png';
import AlimosMobile11 from '../../../assets/mobile/Alimos/Alimos11.png';
import AlimosMobile12 from '../../../assets/mobile/Alimos/Alimos12.png';

import '../ProjectStructure.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { getScreenWidth } from '../../../utils/getScreenWidth';

function Alimos() {

    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    },  [window.innerWidth])

    return (
        <div className='alimos-container'>
            <CustomNavbar />
            <div className='project-content'>
                <div className='project-content-header'>
                    <img src={(screenWidth <= 480 ) ? AlimosMobileHeader : AlimosHeader} alt="alimos" />
                    <p>Commercial building on Alimos Ave.</p>
                </div>
                <div className='project-content-paragraph'>
                    <p>
                        PROJECT: Commercial building on Alimos Ave.
                        <br />
                        TYPE: Commercial
                        <br />
                        LOCATION: Argyroupoli, Greece
                    </p>
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? AlimosMobile01 : Alimos01} alt="Alimos01" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? AlimosMobile02 : Alimos02} alt="Alimos02" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? AlimosMobile03 : Alimos03} alt="Alimos03" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? AlimosMobile04 : Alimos04} alt="Alimos04" />
                    <img src={(screenWidth <= 480 ) ? AlimosMobile05 : Alimos05} alt="Alimos05" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? AlimosMobile06 : Alimos06} alt="Alimos07" />
                    <img src={(screenWidth <= 480 ) ? AlimosMobile07 : Alimos07} alt="Alimos08" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? AlimosMobile08 : Alimos08} alt="Alimos07" />
                    <img src={(screenWidth <= 480 ) ? AlimosMobile09 : Alimos09} alt="Alimos08" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? AlimosMobile10 : Alimos10} alt="Alimos07" />
                    <img src={(screenWidth <= 480 ) ? AlimosMobile11 : Alimos11} alt="Alimos08" />
                </div>
                <div className='project-content-one-img'>
                    <img src={(screenWidth <= 480 ) ? AlimosMobile12 : Alimos12} alt="Alimos12" />
                </div>
            </div>
            <Prefooter />
            <Footer />
        </div>
    )
}

export default Alimos