import React from 'react'
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer"

import './section.css'

function Section() {

    const { t } = useTranslation();
    const [ref, inView] = useInView({ threshold: 0.5 });

    return (
        <div className='studio-section'>
            <div className={!inView ? "studio-section-profile services-animation" : null }>
                <h4>{t('studio-section-profile-title')}</h4>
                <p>
                    {t('studio-section-profile-body')}
                </p>
                <p>
                    {t('studio-section-profile-body2')}
                </p>
                <p>
                    {t('studio-section-profile-body3')}
                </p>
            </div>
            <div className={!inView ? "studio-section-philosophy services-animation" : null }>
                <h4>{t('studio-section-philosophy-title')}</h4>
                <p>
                    {t('studio-section-philosophy-body')}
                </p>
                <p>
                    {t('studio-section-philosophy-body2')}
                </p>
                <p>
                    {t('studio-section-philosophy-body3')}
                </p>
            </div>
            <div className={!inView ? "studio-section-people services-animation" : null }>
                <h4>{t('studio-section-people-title')}</h4>
                <p>
                    {t('studio-section-people-body')}
                </p>
                <p>
                    {t('studio-section-people-body2')}
                </p>
                <p>
                    {t('studio-section-people-body3')}
                </p>
            </div>
        </div>
    )
}

export default Section
