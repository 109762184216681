import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Projects from '../../../assets/projects.png';
import ProjectsMobile from '../../../assets/mobile/projects.png';
import { getScreenWidth } from '../../../utils/getScreenWidth';
import './header.css'

function ProjectsHeader() {
    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    }, [window.innerWidth])

    return (
        <div className='projects-header-container'>
            <img src={(screenWidth <= 480) ? ProjectsMobile : Projects} alt="projects-header"/>
            <p>PROJECTS</p>
        </div>
    )
}

export default ProjectsHeader
