import React from 'react'
import { Link } from 'react-router-dom'

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';


import 'bootstrap/dist/css/bootstrap.min.css'

import Logo from '../../assets/mak-architects-logo.png'
import HamburgerMenu from '../../assets/hamburger-menu.png'
import './Navbar.css'
import { NavDropdown } from 'react-bootstrap';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ['en', 'el'],
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

        fallbackLng: "en",
        detection: {
            // order and from where user language should be detected
            order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
            caches: ['cookie'],

        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        }
    });

const CustomNavbar = () => {

    const { t } = useTranslation();


    return (
        <div className='custom-navbar fixed-top'>

            <div className='navbar-dropdown'>
                <NavDropdown title={<img src={HamburgerMenu} alt='hamburger-menu' />} id="collasible-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">
                        <Link to="/studio">
                            {t('navbar-studio')}
                        </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                        <Link to="/services">
                            {t('navbar-services')}
                        </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                        <Link to="/projects">
                            PROJECTS
                        </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.4">
                        <Link to="/contact">
                            {t('navbar-contact')}
                        </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item>
                        <button className='navbar-translation-menu-btn' onClick={() => i18n.changeLanguage("en")}>EN</button>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                        <button className='navbar-translation-menu-btn' onClick={() => i18n.changeLanguage("el")}>GR</button>

                    </NavDropdown.Item>
                </NavDropdown>
            </div>

            <div className="navbar-links">
                <div className="navbar-links-logo">
                    <Link to="/">
                        <img src={Logo} alt='mak-architects-logo' />
                    </Link>
                </div>
            </div>
            <div className='navbar-links-menu'>
                <div className='navbar-links-menu-container'>
                    <Link to="/studio">
                        {t('navbar-studio')}
                    </Link>
                    <Link to="/services">
                        {t('navbar-services')}
                    </Link>
                    <Link to="/projects">
                        PROJECTS
                    </Link>
                    <Link to="/contact">
                        {t('navbar-contact')}
                    </Link>
                </div>
            </div>
            <div className='navbar-translation-menu'>
                <div className='navbar-translation-menu-row'>
                    <button className='navbar-translation-menu-btn' onClick={() => i18n.changeLanguage("en")}>EN</button>
                    <button className='navbar-translation-menu-btn' onClick={() => i18n.changeLanguage("el")}>GR</button>
                </div>
            </div>



        </div>
    )
}

export default CustomNavbar
