import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha'
import emailjs from 'emailjs-com';
import './ContactUsForm.css'


const ContactUsForm = () => {

    var captchaValue = "";

    function onChange(value) {
        captchaValue = value;
        // console.log('Captcha value:', captchaValue);
        if (captchaValue === "" || captchaValue == null) {
            document.getElementById('recaptcha-verification-text').style.display = "block";
        } else {
            document.getElementById('recaptcha-verification-text').style.display = "none";
        }
    }

    const form = useRef();

    const SendEmail = (e) => {
        e.preventDefault();

        if (document.getElementById('username').value === "" ||
            document.getElementById('email').value === "" ||
            document.getElementById('mobile').value === "" ||
            document.getElementById('message').value === ""
        ) {
            document.getElementById('fill-fields-verification-text').style.display = "block";
        } else {
            document.getElementById('fill-fields-verification-text').style.display = "none";
            if (captchaValue !== "" && captchaValue != null) {
                emailjs.sendForm('service_5rpsimg', 'template_sac2mqb', form.current, 'Ffpxn5-AyikRPcjLu')
                    .then((result) => {
                        document.getElementById('valid-email-verification-text').style.display = "none";
                        document.getElementById('invalid-recaptcha').style.display = "none";
                        document.getElementById('email-sent').style.display = "block";
                        document.getElementById('username').value = "";
                        document.getElementById('email').value = "";
                        document.getElementById('subject').value = "";
                        document.getElementById('message').value = "";
                    }, (error) => {
                        console.log(error.text);
                        if (error.text.includes("501")) {
                            document.getElementById('valid-email-verification-text').style.display = "block";
                        } else if (error.text.includes("timeout")) {
                            document.getElementById('invalid-recaptcha').style.display = "block";
                        }
                    });
            } else {
                console.log("recaptcha needed");
                document.getElementById('recaptcha-verification-text').style.display = "block";
            }
        }

        e.target.reset();


    };

    return (
        <div className="contact-us-container">
            <div className="contact-us-row">
                <h1>GET IN TOUCH</h1>
                <p>Fill out the form and we will get back to you!</p>
                <form className="contact-us-form" ref={form} onSubmit={SendEmail}>
                    <div className='contact-us-form-row'>
                        <div className='contact-us-form-col'>
                            <div className="form-name">
                                <input
                                    type="text"
                                    name="name"
                                    className="username"
                                    id='username'
                                    tabIndex="1"
                                    placeholder='Your name'
                                />
                            </div>
                            <div className="form-email">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="email"
                                    placeholder="Your email address"
                                    tabIndex="3"
                                />
                            </div>
                            <div className="form-email">
                                <input
                                    type="text"
                                    name="mobile"
                                    id="mobile"
                                    className="mobile"
                                    placeholder="Your mobile"
                                    tabIndex="3"
                                />
                            </div>
                            <div className="form-subject">
                                <label id='contact-form-interested'>I am interested in</label>
                                <select name="subject" id="subject">
                                    <option value="design">Design</option>
                                    <option value="new_build">New Build</option>
                                    <option value="renovation">Renovation</option>
                                    <option value="engineering">Engineering</option>
                                    <option value="project_management">Project Management</option>
                                </select>
                            </div>
                        </div>
                        <div className='contact-us-form-col'>
                            <div className="form-message">
                                <textarea
                                    placeholder="You may freely express yourself"
                                    className="message"
                                    name="message"
                                    id='message'
                                />
                            </div>
                            <div className="form-send-button">
                                <button type="button" onClick={SendEmail}>SEND MESSAGE</button>
                            </div>
                        </div>
                    </div>
                    <div className='contact-us-form-row'>
                        <div className='recaptcha'>
                            <ReCAPTCHA
                                sitekey="6LfSrnMfAAAAAPI0kEq4-P-PjYThEWAuUMQkYQaU"
                                onChange={onChange}
                                className="recaptcha-box"
                            />
                            <p id='recaptcha-verification-text'>
                                Complete reCAPTCHA verification.
                            </p>
                            <p id='valid-email-verification-text'>
                                Please enter a valid email.
                            </p>
                            <p id='fill-fields-verification-text'>
                                Please fill all of the form's fields.
                            </p>
                            <p id='invalid-recaptcha'>
                                Invalid reCAPTCHA. Please reload your page.
                            </p>
                            <p id='email-sent'>
                                Your email was sent successfully.
                            </p>
                        </div>
                    </div>






                </form>
            </div>
        </div>
    )
}

export default ContactUsForm
