import React from 'react'
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer"

import Employee from '../../../assets/employee.png'
import AmaliaMakraki from '../../../assets/AmaliaMakraki.png'
import DimitriosMakrakis from '../../../assets/DimitriosMakrakis.png'
import ManosMakrakis from '../../../assets/ManosMakrakis.png'

import './people.css'

function People() {

    const { t } = useTranslation();
    const [ref, inView] = useInView({ threshold: 0.5 });

    return (
        <div className={!inView ? "studio-people services-animation" : null }>
            <div className='studio-people-card'>
                <div className='studio-people-card-img'>
                    <img src={DimitriosMakrakis} alt='employee'/>
                </div>
                <div className='studio-people-card-body'>
                    <p>
                        <strong>{t('studio-people-card-body-dimitris-makrakis')}</strong>
                        <br/>
                        {t('studio-people-card-body-dimitris-makrakis2')}
                    </p>
                </div>
            </div>
            
            <div className='studio-people-card'>
                <div className='studio-people-card-img'>
                    <img src={AmaliaMakraki} alt='employee'/>
                </div>
                <div className='studio-people-card-body'>
                    <p>
                    <strong>{t('studio-people-card-body-amalia-makraki')}</strong>
                        <br />
                        {t('studio-people-card-body-amalia-makraki2')}
                    </p>
                </div>
            </div>

            <div className='studio-people-card'>
                <div className='studio-people-card-img'>
                    <img src={ManosMakrakis} alt='employee'/>
                </div>
                <div className='studio-people-card-body'>
                    <p>
                    <strong>{t('studio-people-card-body-emmanouil-makrakis')}</strong>
                        <br />
                        {t('studio-people-card-body-emmanouil-makrakis2')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default People
