import React, { Component } from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'

import { Homepage, Studio, Services, Projects, Contact, Voula, Paros, Alimos, Bubo, Drosia, EKO, Glyfada, Keranis, Olimpou, Panourgia } from '../containers'

import ScrollToTop from '../components/ScrollToTop'


class Routing extends Component {
    state = { }
    render() {
        return (
            <BrowserRouter>
            <ScrollToTop/>
                <Routes>
                    <Route path="/" element={<Homepage/>}/>
                    <Route path="/studio" element={<Studio/>}/>
                    <Route path="/services" element={<Services/>}/>
                    <Route path="/projects" element={<Projects/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/voula" element={<Voula/>}/>
                    <Route path="/paros" element={<Paros/>}/>
                    <Route path="/alimos" element={<Alimos/>}/>
                    <Route path="/bubo" element={<Bubo/>}/>
                    <Route path="/drosia" element={<Drosia/>}/>
                    <Route path="/EKO-gas-station" element={<EKO/>}/>
                    <Route path="/glyfada" element={<Glyfada/>}/>
                    <Route path="/keranis" element={<Keranis/>}/>
                    <Route path="/olimpou" element={<Olimpou/>}/>
                    <Route path="/panourgia" element={<Panourgia/>}/>
                </Routes>
            </BrowserRouter>
        );
    }
}

export default Routing
