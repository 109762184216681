import React from 'react';
import CustomNavbar from '../../../components/Navbar/Navbar';
import Prefooter from '../../prefooter/prefooter';
import Footer from '../../footer/footer';

import OlimpouHeader from '../../../assets/Olympou/OlympouHeader.png';
import Olympou01 from '../../../assets/Olympou/Olympou01.png';
import Olympou02 from '../../../assets/Olympou/Olympou02.png';
import Olympou03 from '../../../assets/Olympou/Olympou03.png';
import Olympou04 from '../../../assets/Olympou/Olympou04.png';
import Olympou05 from '../../../assets/Olympou/Olympou05.png';
import Olympou06 from '../../../assets/Olympou/Olympou06.png';
import Olympou07 from '../../../assets/Olympou/Olympou07.png';
import Olympou08 from '../../../assets/Olympou/Olympou08.png';
import Olympou09 from '../../../assets/Olympou/Olympou09.png';
import Olympou10 from '../../../assets/Olympou/Olympou10.png';
import OlympouMobileHeader from '../../../assets/mobile/Olympou/OlympouHeader.png';
import OlympouMobile01 from '../../../assets/mobile/Olympou/Olympou01.png';
import OlympouMobile02 from '../../../assets/mobile/Olympou/Olympou02.png';
import OlympouMobile03 from '../../../assets/mobile/Olympou/Olympou03.png';
import OlympouMobile04 from '../../../assets/mobile/Olympou/Olympou04.png';
import OlympouMobile05 from '../../../assets/mobile/Olympou/Olympou05.png';
import OlympouMobile06 from '../../../assets/mobile/Olympou/Olympou06.png';
import OlympouMobile07 from '../../../assets/mobile/Olympou/Olympou07.png';
import OlympouMobile08 from '../../../assets/mobile/Olympou/Olympou08.png';
import OlympouMobile09 from '../../../assets/mobile/Olympou/Olympou09.png';
import OlympouMobile10 from '../../../assets/mobile/Olympou/Olympou10.png';
import '../ProjectStructure.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { getScreenWidth } from '../../../utils/getScreenWidth';

function Olimpou() {

    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    },  [window.innerWidth])
    return (
        <div className='olimpou-container'>
            <CustomNavbar />
            <div className='project-content'>
                <div className='project-content-header'>
                    <img src={(screenWidth <= 480 ) ? OlympouMobileHeader : OlimpouHeader} alt="olimpou" />
                    <p>Block of flats</p>
                </div>
                <div className='project-content-paragraph'>
                    <p>
                        PROJECT: Block of flats
                        <br />
                        TYPE: Residential
                        <br />
                        LOCATION: Nikaia, Greece
                    </p>
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? OlympouMobile01 : Olympou01} alt="Olympou01" />
                    <img src={(screenWidth <= 480 ) ? OlympouMobile02 : Olympou02} alt="Olympou02" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? OlympouMobile03 : Olympou03} alt="Olympou03" />
                    <img src={(screenWidth <= 480 ) ? OlympouMobile04 : Olympou04} alt="Olympou04" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? OlympouMobile05 : Olympou05} alt="Olympou05" />
                    <img src={(screenWidth <= 480 ) ? OlympouMobile06 : Olympou06} alt="Olympou06" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? OlympouMobile07 : Olympou07} alt="Olympou07" />
                    <img src={(screenWidth <= 480 ) ? OlympouMobile08 : Olympou08} alt="Olympou08" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480 ) ? OlympouMobile09 : Olympou09} alt="Olympou09" />
                    <img src={(screenWidth <= 480 ) ? OlympouMobile10 : Olympou10} alt="Olympou10" />
                </div>
            </div>
            <Prefooter />
            <Footer />
        </div>
    )
}

export default Olimpou