import React from 'react'
import { useTranslation } from 'react-i18next';
import { Ekali, Nikea } from '../..'

import './onsite.css'

function Onsite() {
    const { t } = useTranslation();
    return (
        <div className='onsite-container'>
            <div className='onsite-container-map'>
                <Ekali/>
                <h5>{t('contact-us-ekali')}</h5>
                <p>{t('contact-us-ekali-2')}</p>
            </div>
            <div className='onsite-container-map'>
                <Nikea/>
                <h5>{t('contact-us-nikea')}</h5>
                <p>{t('contact-us-nikea-2')}</p>
            </div>
        </div>
    )
}

export default Onsite
