import React from 'react';
import CustomNavbar from '../../../components/Navbar/Navbar';
import Prefooter from '../../prefooter/prefooter';
import Footer from '../../footer/footer';

import GlyfadaHeader from '../../../assets/Glyfada/GlyfadaHeader.png';
import Glyfada01 from '../../../assets/Glyfada/Glyfada01.png';
import Glyfada02 from '../../../assets/Glyfada/Glyfada02.png';
import Glyfada03 from '../../../assets/Glyfada/Glyfada03.png';
import Glyfada04 from '../../../assets/Glyfada/Glyfada04.png';
import Glyfada05 from '../../../assets/Glyfada/Glyfada05.png';
import Glyfada06 from '../../../assets/Glyfada/Glyfada06.png';
import Glyfada07 from '../../../assets/Glyfada/Glyfada07.png';
import Glyfada08 from '../../../assets/Glyfada/Glyfada08.png';
import Glyfada09 from '../../../assets/Glyfada/Glyfada09.png';
import Glyfada10 from '../../../assets/Glyfada/Glyfada10.png';
import GlyfadaMobileHeader from '../../../assets/mobile/Glyfada/GlyfadaHeader.png';
import GlyfadaMobile01 from '../../../assets/mobile/Glyfada/Glyfada01.png';
import GlyfadaMobile02 from '../../../assets/Glyfada/Glyfada02.png';
import GlyfadaMobile03 from '../../../assets/mobile/Glyfada/Glyfada03.png';
import GlyfadaMobile04 from '../../../assets/mobile/Glyfada/Glyfada04.png';
import GlyfadaMobile05 from '../../../assets/mobile/Glyfada/Glyfada05.png';
import GlyfadaMobile06 from '../../../assets/mobile/Glyfada/Glyfada06.png';
import GlyfadaMobile07 from '../../../assets/mobile/Glyfada/Glyfada07.png';
import GlyfadaMobile08 from '../../../assets/mobile/Glyfada/Glyfada08.png';
import GlyfadaMobile09 from '../../../assets/mobile/Glyfada/Glyfada09.png';
import GlyfadaMobile10 from '../../../assets/mobile/Glyfada/Glyfada10.png';
import '../ProjectStructure.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { getScreenWidth } from '../../../utils/getScreenWidth';

function Glyfada() {

    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    }, [window.innerWidth])

    return (
        <div className='glyfada-container'>
            <CustomNavbar />
            <div className='project-content'>
                <div className='project-content-header'>
                    <img src={(screenWidth <= 480) ? GlyfadaMobileHeader : GlyfadaHeader} alt="glyfada" />
                    <p>Block of flats in Glyfada</p>
                </div>
                <div className='project-content-paragraph'>
                    <p>
                        PROJECT: Block of flats in Glyfada
                        <br />
                        TYPE: Residential
                        <br />
                        LOCATION: Glyfada, Greece
                    </p>
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480) ? GlyfadaMobile02 : Glyfada02} alt="Glyfada03" />
                    <img src={(screenWidth <= 480) ? GlyfadaMobile03 : Glyfada03} alt="Glyfada02" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480) ? GlyfadaMobile04 : Glyfada04} alt="Glyfada03" />
                    <img src={(screenWidth <= 480) ? GlyfadaMobile05 : Glyfada05} alt="Glyfada04" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480) ? GlyfadaMobile06 : Glyfada06} alt="Glyfada05" />
                    <img src={(screenWidth <= 480) ? GlyfadaMobile07 : Glyfada07} alt="Glyfada06" />
                </div>
                <div className='project-content-two-imgs'>
                    <img src={(screenWidth <= 480) ? GlyfadaMobile08 : Glyfada08} alt="Glyfada07" />
                    <img src={(screenWidth <= 480) ? GlyfadaMobile09 : Glyfada09} alt="Glyfada09" />
                </div>
            </div>
            <Prefooter />
            <Footer />
        </div>
    )
}

export default Glyfada