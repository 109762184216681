import { React } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'

import Bubo from '../../../assets/Bubo/BuboHeader.png'
import Drosia from '../../../assets/Drosia/DrosiaHeader.png'
import Paros from '../../../assets/Paros/ParosHeader.png'
import Keranis from '../../../assets/Keranis/KeranisHeader.png'
import Alimos from '../../../assets/Alimos/AlimosHeader.png'
import GasStation from '../../../assets/EKO/EKOHeader.png'
import Voula from '../../../assets/Voula/VoulaHeader.png'
import Glyfada from '../../../assets/Glyfada/GlyfadaHeader.png'
import Olimpou from '../../../assets/Olympou/OlympouHeader.png'
import Panouria from '../../../assets/Panourgia/PanourgiaHeader.png'
import './gallery.css'
import { useNavigate } from 'react-router'






function Gallery() {

    const navigate = useNavigate();

    const onProjectClick = (path) => {
        navigate(path);
    }

    return (
        <div className='projects-gallery-container'>

            <div className='projects-gallery-container-two-images-row'>
                <div className='projects-gallery-container-two-images-row-img'>
                    <div className='projects-gallery-container-two-images-row-img-card' onClick={() => {onProjectClick('/bubo')}}>
                        <img src={Bubo} alt='architecture' />
                        <div className='projects-gallery-container-two-images-row-img-card-body'>
                            <p>
                                Restaurant & Bar in Athens
                            </p>
                        </div>
                    </div>
                </div>
                <div className='projects-gallery-container-two-images-row-img'>
                    <div className='projects-gallery-container-two-images-row-img-card' onClick={() => {onProjectClick('/drosia')}}>
                        <img src={Drosia} alt='architecture-design' />
                        <div className='projects-gallery-container-two-images-row-img-card-body'>
                            <p>
                                Complex of Residences in Drosia
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='projects-gallery-container-two-images-row'>
                <div className='projects-gallery-container-two-images-row-img'>
                    <div className='projects-gallery-container-two-images-row-img-card' onClick={() => {onProjectClick('/paros')}}>
                        <img src={Paros} alt='architecture' />
                        <div className='projects-gallery-container-two-images-row-img-card-body'>
                            <p>
                                Villa Grey in Paros
                            </p>
                        </div>
                    </div>
                </div>
                <div className='projects-gallery-container-two-images-row-img'>
                    <div className='projects-gallery-container-two-images-row-img-card' onClick={() => {onProjectClick('/keranis')}}>
                        <img src={Keranis} alt='architecture-design' />
                        <div className='projects-gallery-container-two-images-row-img-card-body'>
                            <p>
                                Inhabiting Keranis
                                <br/>(Proposal for the reconstruction of the Keranis tobacco factory)
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='projects-gallery-container-two-images-row'>
                <div className='projects-gallery-container-two-images-row-img'>
                    <div className='projects-gallery-container-two-images-row-img-card' onClick={() => {onProjectClick('/alimos')}}>
                        <img src={Alimos} alt='architecture' />
                        <div className='projects-gallery-container-two-images-row-img-card-body'>
                            <p>
                                Commercial building on Alimos Ave.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='projects-gallery-container-two-images-row-img'>
                    <div className='projects-gallery-container-two-images-row-img-card' onClick={() => {onProjectClick('/EKO-gas-station')}}>
                        <img src={GasStation} alt='architecture-design' />
                        <div className='projects-gallery-container-two-images-row-img-card-body'>
                            <p>
                                Gas Station in Kifissos
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='projects-gallery-container-two-images-row'>
                <div className='projects-gallery-container-two-images-row-img'>
                    <div className='projects-gallery-container-two-images-row-img-card' onClick={() => {onProjectClick('/voula')}}>
                        <img src={Voula} alt='architecture' />
                        <div className='projects-gallery-container-two-images-row-img-card-body'>
                            <p>
                                Apartment Building Extension
                            </p>
                        </div>
                    </div>
                </div>
                <div className='projects-gallery-container-two-images-row-img'>
                    <div className='projects-gallery-container-two-images-row-img-card' onClick={() => {onProjectClick('/glyfada')}}>
                        <img src={Glyfada} alt='architecture-design' />
                        <div className='projects-gallery-container-two-images-row-img-card-body'>
                            <p>
                                Block of flats in Glyfada
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='projects-gallery-container-two-images-row'>
                <div className='projects-gallery-container-two-images-row-img'>
                    <div className='projects-gallery-container-two-images-row-img-card' onClick={() => {onProjectClick('/olimpou')}}>
                        <img src={Olimpou} alt='architecture' />
                        <div className='projects-gallery-container-two-images-row-img-card-body'>
                            <p>
                                Block of flats
                            </p>
                        </div>
                    </div>
                </div>
                <div className='projects-gallery-container-two-images-row-img'>
                    <div className='projects-gallery-container-two-images-row-img-card' onClick={() => {onProjectClick('/panourgia')}}>
                        <img src={Panouria} alt='architecture-design' />
                        <div className='projects-gallery-container-two-images-row-img-card-body'>
                            <p>
                                Block of flats
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Gallery
