import React from 'react';
import './homepagefooter.css'

import Facebook from '../../../assets/facebook_white.png';
import Instagram from '../../../assets/instagram_white.png';

function HomepageFooter() {
    return (
        <div className="homepage-footer-container">
            <div className='homepage-footer-container-social'>
            <a href="https://www.facebook.com/makarchitectsengineers" target="_blank" rel="noreferrer">
                    <img src={Facebook} alt="facebook-logo" />
                </a>
                <a href="https://www.instagram.com/mak.architects.engineers/" target="_blank" rel="noreferrer">
                    <img src={Instagram} alt="instagram-logo" />
                </a>
            </div>
        </div>
    )
}

export default HomepageFooter