import React from 'react'
import './section.css'

function section() {
    return (
        <div className='contact-section-container'>
            <div className='contact-section-container-card'>
                <p className='contact-section-container-card-title'>PHONE</p>
                <a href='tel:2169009989' className='contact-section-container-card-p'>
                    +30 2169009989</a>
                <a href='tel:6981127122' className='contact-section-container-card-p'>+30 6981127122</a>
            </div>
            <div className='contact-section-container-card'>
                <p className='contact-section-container-card-title'>EMAIL</p>
                <a href = "mailto: mak.architects.engineers@gmail.com" className='contact-section-container-card-p'>mak.architects.engineers@gmail.com</a>
                <a href = "mailto: info@makarchitectsengineers.com" className='contact-section-container-card-p'>info@makarchitectsengineers.com</a>
            </div>
            <div className='contact-section-container-card'>
                <p className='contact-section-container-card-title'>FOLLOW US</p>
                <a href="https://www.instagram.com/mak.architects.engineers/" target="_blank" rel="noreferrer" className='contact-section-container-card-p'>
                    Instagram
                </a>
                <a href="https://www.facebook.com/makarchitectsengineers" target="_blank" rel="noreferrer" className='contact-section-container-card-p'>
                    Facebook
                </a>
            </div>

        </div>
    )
}

export default section
