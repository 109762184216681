import React from 'react'
import { useTranslation } from 'react-i18next';
import Header from '../../../assets/studio.jpg';
import HeaderMobile from '../../../assets/mobile/studio.png';
import './header.css'
import { useState } from 'react';
import { getScreenWidth } from '../../../utils/getScreenWidth';
import { useEffect } from 'react';

function StudioHeader() {
    const { t } = useTranslation();
    const [screenWidth, setScreenWidth] = useState(getScreenWidth());

    useEffect(() => {
        setScreenWidth(getScreenWidth());
    }, [window.innerWidth])

    return (
        <div className='studio-header-container'>
            <img src={(screenWidth <= 480) ? HeaderMobile : Header} alt="studio-header"/>
            <p>{t('navbar-studio')}</p>
        </div>
    )
}

export default StudioHeader
