import React from 'react'
import { Footer, Prefooter, StudioHeader, StudioPeople, StudioSection } from '..'
import Navbar from '../../components/Navbar/Navbar'

export default function studio() {
    return (
        <div className='studio-container'>
            <Navbar/>
            <StudioHeader/>
            <StudioSection/>
            <StudioPeople/>
            <Prefooter/>
            <Footer/>
        </div>
    )
}
