import React from 'react'
import { Hero } from '..'
import { HomepageNavbar } from '../../components'
import HomepageFooter from '../footer/homepagefooter/homepagefooter'

import './homepage.css'

function homepage() {
    return (
        <div className='homepage-container'>
            <HomepageNavbar/>
            <Hero/>
            <HomepageFooter/>
        </div>
        
    )
}

export default homepage
