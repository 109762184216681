import React from 'react'
import { Expertize, Footer, Prefooter, ServicesHeader, ServicesSection } from '..'
import Navbar from '../../components/Navbar/Navbar'
import './services.css'

function services() {
    return (
        <div className='services-container'>
            <Navbar/>
            <ServicesHeader/>
            <ServicesSection/>
            <Expertize/>
            {/* <ServicesBuildSmart/>
            <ServicesDesignYourSpace/> */}
            <Prefooter/>
            <Footer/>
        </div>
    )
}

export default services
